<template>
  <div>
    <v-row justify="center" class="my-0 py-4">
      <v-col cols="11" class="white rounded-xl">
        <v-row>
          <v-col cols="12" offset-md="1" md="10" offset-xl="2" xl="8">
            <v-row>
              <v-col cols="12">
                <h1 class="text-center wc-h5 mt-5 text-primary-variant">
                  ¿Tienes alguna duda?
                </h1>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="12">
                <v-sheet color="accent rounded-xl pl-3">
                  <v-text-field
                    v-model="searchInfo"
                    filled
                    rounded
                    dense
                    background-color="#ffffff"
                    placeholder="Busca en la documentacion"
                    hide-details="auto"
                    class="rounded-search"
                    clearable
                    v-on:keyup.enter="searchQuestions()"
                    @click:clear="(searchInfo = ''), searchQuestions()"
                  >
                    <template v-slot:prepend>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon small v-on="on">
                            <v-icon>fa fa-search</v-icon>
                          </v-btn>
                        </template>
                        Clic para buscar
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-sheet>
              </v-col>
              <v-row class="pt-5 justify-center">
                <v-btn
                  color="#1B273E"
                  class="mx-2 white--text font-weight-bold"
                  rounded
                  @click.prevent="downloadManual()"
                >
                  Manual de usuario
                  <v-icon small class="ml-2">mdi-download</v-icon>
                </v-btn>
              </v-row>
            </v-row>
          </v-col>
          <v-col cols="12" offset-md="1" md="10">
            <help-desk-card-list
              v-if="!loaderVisible"
              :items="questions"
            ></help-desk-card-list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Endpoints from "@/share/Endpoints";
import HelpDeskCardList from "@/components/shared/HelpDesk/HelpDeskCardList";

import { mapState, mapMutations } from "vuex";

export default {
  components: {

    HelpDeskCardList,
  },
  computed: {
    ...mapState("loader", ["loaderVisible"]),
  },
  data() {
    return {
      searchInfo: "",
      questions: [],
    };
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    async searchQuestions() {
      this.loading();
      let response = await this.axios.get(
        `${Endpoints.helpDesk}?search=${this.searchInfo}`
      );
      console.log(response);
      if (response.status >= 200 && response.status < 300) {
        this.questions = response.data.helpDesk;
      } else {
        this.show({
          text: "Ha ocurrido un problema al realizar la busqueda",
          color: "error",
        });
      }
      this.loaded();
    },
    downloadManual() {
      const url =
        "https://wechamber-v3-files.s3.us-east-2.amazonaws.com/Manual+de+usuario+WeChamber.pdf";
      window.open(url, "_blank");
    },
  },
  async mounted() {
    await this.searchQuestions();
  },
};
</script>
<style scoped>
.rounded-search {
  border-radius: 0px 24px 24px 0px !important;
}
</style>