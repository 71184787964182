<template>
  <v-hover v-slot="{ hover }">
    <v-card
      class="rounded-xl mx-auto question-card px-3 pt-2"
      outlined
      max-width="400"
      :elevation="hover ? 12 : 0"
      :class="{ 'on-hover': hover }"
    >
      <v-card-subtitle>
        <v-row dense align="center">
          <v-col cols="12" class="text-center">
            <div :class="item.showMore ? '' : 'max-height'">
              <span class="text-center wc-subtitle-1 text-primary-variant">
                {{ item.question }}
                <br />
                <br />
                <p class="text-justify wc-caption-1 text-primary-variant">
                  {{ item.answer }}
                </p>
              </span>
            </div>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-actions class="mt-auto">
        <v-row class="ma-0" justify="center">
          <v-btn
            v-if="item.showMore"
            class="wc-button font-weight-black"
            :color="color.primaryVariant"
            text
            rounded
            elevation="0"
            @click="item.showMore = false"
          >
            Ver menos
          </v-btn>
          <v-btn
            v-else
            class="wc-button font-weight-black"
            :color="color.primaryVariant"
            text
            rounded
            elevation="0"
            @click="item.showMore = true"
          >
            Ver más
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>
<script>
import { colors } from "@/constants/colors.js";
export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      questionSelectedId: null,
      color: colors,
    };
  },
};
</script>
<style scoped>
.max-height {
  max-height: 250px;
  overflow: hidden;
}
</style>