<template>
  <v-row
    class="mt-5"
    :class="$vuetify.breakpoint.mdAndUp ? 'mb-4' : 'mb-10'"
    :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
  >
    <v-col
      cols="12"
      sm="6"
      md="4"
      v-for="item in localItems"
      :key="item.id"
      class="mb-5"
    >
      <help-desk-card
        :item="item"
      >
      </help-desk-card>
    </v-col>
    <!-- INICIO Dialog para confirmar la eliminación de un evento -->
    <v-dialog v-model="questionDialog" width="600">
      <v-card color="grey lighten-3">
        <v-card-text>
          <v-row justify="center">
            <v-col cols="10" md="7" class="mt-8 d-flex justify-center">
              <v-img
                class=""
                src="@/assets/images/weChamber/logo-WC-microsite.svg"
                max-width="60"
                height="auto"
                contain
              ></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mt-2">
              <p
                class="text-center secondary--text font-weight-bold title mb-0"
              >
                {{ currentQuestion.question }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p class="subtitle-1 text-center mb-0">
                {{ currentQuestion.answer }}
              </p>
              <!--<p class="subtitle-1 text-center">Los datos del evento no podrán ser recuperados.</p>-->
            </v-col>
          </v-row>
          <v-row
            class="d-flex pb-2 mt-5"
            :class="
              $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
            "
          >
            <!--<v-btn class="font-weight-bold black--text mx-2" color="grey lighten-3" rounded elevation="0" @click="questionDialog = false">
            Cancelar
          </v-btn>-->
            <v-btn
              class="font-weight-bold mx-2"
              color="primary"
              rounded
              elevation="0"
              @click="questionDialog = false"
            >
              Cerrar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- FIN Dialog para confirmar la eliminación de un evento -->
  </v-row>
</template>

<script>
import HelpDeskCard from "./HelpDeskCard";
import {colors} from "@/constants/colors.js"

export default {
  components: {
    HelpDeskCard,
  },
  props: {
    items: { type: Array, required: true },
  },
  data() {
    return {
      questionSelectedId: null,
      currentQuestion: {},
      questionDialog: false,
      color: colors,
      localItems: [],
    };
  },
  mounted() {
    //add showMore property to each item
    this.localItems = this.items.map((item) => {
      return { ...item, showMore: false };
    });
  },
  watch: {
    items: {
      handler: function (newVal, oldVal) {
        this.localItems = newVal.map((item) => {
          return { ...item, showMore: false };
        });
      },
      deep: true,
    },
  },
};
</script>